<template>
<div class=" mx-auto">
        <div>
            <Loading :isLoading= "this.isLoading" />
            <div>
                <form @submit="Next" class="px-8 pt-6 pb-8 mb-4" style="text-align: left;">
                    <br>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <center>
                                <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl" style="color: #82385b; font-weight: bold; margin-top:0px;">
                                    Información Laboral</p>
                            </center>
                        </div>
                        <h4 style="text-align:center;">Paso 4 de 10</h4>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <center>
                                <!-- <p v-if="errors.length">
                                <ul>
                                    <li v-for="error in errors"><h5 style="color:red">{{ error }}</h5></li>
                                </ul>
                                </p> -->
                                <br>
                            </center>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                        <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
                            <div class="border">
                                <div class="center">
                                  <div class="row">
                                    <div class="row" style="margin-left:5px">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                        <label style="text-align: left;font-size:15px;color:black;color: #6E6E6E;">Estatus:</label>
                                        <div class="input-group" style="margin-top:-5px;">
                                          <input type="radio" name="rdbStatus" value="ACTIVO" v-model="status" v-on:change="setAdmissionDatePicker()" required style="margin-left:30px;" /> Activo(a)
                                          <input type="radio" name="rdbStatus" value="JUBILADO" v-model="status" v-on:change="setAdmissionDatePicker()" style="margin-left:20px"/> Jubilado(a)
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                    <div v-if="status == 'ACTIVO'" class="row" style="margin-top:5.5px">
                                        <div class="col-sm-6" v-if="false">
                                            <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                <label class="label-input">Clave CT:</label>
                                                <input type="text"  class="form-control inputs-form"
                                                minlength="3" maxlength="20" v-model="keyCt" required>
                                            </div>
                                        </div>
                                        <div class="col-sm-6" v-if="false">
                                          <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                            <label class="label-input">Clave presupuestal:</label>
                                            <input type="text" class="form-control inputs-form"
                                            minlength="4" maxlength="20" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,20}" v-model="presupustalCode" required>
                                          </div>
                                        </div>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                        <label class="label-input">Nombre Centro de Trabajo:</label>
                                        <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                            <input type="text" class="form-control inputs-form"
                                            minlength="4" maxlength="50" pattern="[a-zA-Z-0-9íéñúóáÍÑÁÉÓÚ# ]{4,50}" v-model="nameWork" required>
                                        </div>
                                        <center>
                                            <label class="label-input">{{this.nameWork}}</label>
                                        </center>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="row" style="margin-top:5.5px">
                                      <div class="col-sm-6">
                                          <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                              <label class="label-input">Código Postal:</label>
                                              <input type="number" id="codigoPostal"
                                              class="form-control inputs-form" oninput="validity.valid||(value='');" onKeyPress="if(this.value.length==5) return false;" @keyup="validateCP($event)"
                                              minlength="5" maxlength="5" v-on:blur="validateCP()" min="0" v-model="postalCode" required pattern="[0-9]{5,5}">
                                          </div>
                                      </div>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="row" style="margin-top:5.5px">
                                    <!-- <div class="center">
                                            <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;width:220px">
                                                <label class="label-input">Fecha de Ingreso:</label>
                                                <input type="text" style="border:1px solid #82385b; text-transform: capitalize;margin-top:-0.5px;widght:120px; 
                                                border:1px solid #82385b;margin-top:-5px; border-top-left-radius:5px; border-top-right-radius:5px;
                                                border-bottom-right-radius:5px;border-bottom-left-radius:5px;" id="datepickerAdmissionDate" class="form-control" v-on:change="calculateNumberEntryTwoDates()"
                                                minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="admissionDate" required placeholder="Seleccionar fecha">
                                            </div>
                                        </div> -->
                                    <div class="col-sm-6">
                                        <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                            <label class="label-input">Teléfono:</label>
                                            <input  class="form-control inputs-form" id="cellphone"
                                            minlength="12" maxlength="12"  min="0" v-model="cellphone" @keyup="validateNumberPhone()" required >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                            <label class="label-input">Extensión:</label>
                                            <input type="number" class="form-control inputs-form"
                                                onKeyPress="if(this.value.length==3) return false;" oninput="validity.valid||(value='');"
                                                pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{0,3}" min="0" v-model="phoneExt">
                                        </div>
                                    </div>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                        <label class="label-input">Calle:</label>
                                        <input type="text" class="form-control inputs-form"
                                        minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚíÍ ]{4,50}" v-model="street" required>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                <label class="label-input">Número exterior:</label>
                                                <input type="number" class="form-control inputs-form" required
                                                onKeyPress="if(this.value.length==5) return false;" min="0" oninput="validity.valid||(value='');" v-model="numberExt">
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-6">
                                            <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px;visibility:collapse">
                                                <label class="label-input">Número int:</label>
                                                <input type="text" class="form-control inputs-form"
                                                minlength="3" maxlength="6" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{3,6}" v-model="numberInt">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                        <label class="label-input">Colonia:</label>
                                        <select lang="en" translate="no" class="form-control notranslate" ref="stateSelected" required v-model="colony" style="border:1px solid #82385b;margin-top:-5px;text-transform: uppercase;">
                                            <option v-for="colony in list_colonys" v-bind:value="addUpperCase(colony.nombre)" :key="colony.nombre">{{ addUpperCase(colony.nombre) }}</option>
                                        </select>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="row" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="label-input">Municipio:</label><br>
                                                <label style="font-size:20px;color: #6E6E6E;text-transform: uppercase;">{{this.municipality}}</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="label-input">Ciudad:</label><br>
                                                <label style="font-size:20px;color:black;color: #6E6E6E;text-transform: uppercase;">{{this.city}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="status == 'ACTIVO'" class="row" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="label-input">Estado:</label><br>
                                                <label style="font-size:20px;color:black;color: #6E6E6E;text-transform: uppercase;">{{this.state}}</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                                    <div class="row" style="margin-left:24px; margin-top:0px;margin-right:10px">
                                      <div v-if="status == 'ACTIVO'">
                                        <div class="center">
                                            <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;width:220px">
                                                <label class="label-input">Fecha de Ingreso:</label>
                                                <input type="text" id="datepickerAdmissionDate" class="form-control inputs-form" v-on:change="calculateNumberEntryTwoDates()"
                                                minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="admissionDate" required placeholder="Seleccionar fecha">
                                            </div>
                                        </div>
                                        <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                            <label class="label-input">Categoria:</label>
                                            <select lang="en" translate="no" class="form-control notranslate" ref="stateSelected" required v-model="category" 
                                            style="border:1px solid #82385b;margin-top:-5px;text-transform: uppercase;">
                                                <option value="PREESCOLAR">Preescolar</option>
                                                <option value="PRIMARIA">Primaria</option>
                                                <option value="SECUNDARIA">Secundaria</option>
                                                <option value="MEDIA SUPERIOR">Media superior</option>
                                                <option value="SUPERIOR">Superior</option>
                                                <option value="EDUCACIÓN ESPECIAL">Educación especial</option>
                                                <option value="INDIGENA">Indigena</option>
                                                <option value="PROGRAMAS COMPENSATORIOS">Programas compensatorios</option>
                                                <option value="EDUCACION PARA ADULTOS">Educación para adultos</option>
                                                <option value="EDUCACION TECNOLOGICA">Educación tecnológica</option>
                                                <option value="EDUCACION NORMAL">Educación normal</option>
                                            </select>
                                        </div>
                                        <div class="row" style="margin-left:5px">
                                            <div class="form-group">
                                                <label class="label-input">Puesto/Ocupación:</label>
                                                <select class="form-control" id="selectoccupation" ref="occupationSelected" required v-model="occupation"
                                                  style="border: 1px solid #82385b; margin-top: -5px">
                                                  <option v-for="element in occupation_list" v-bind:value="addUpperCase(element.occupation)" :key="element.occupation">{{ addUpperCase(element.occupation) }}</option>
                                                </select>
                                            </div>
                                        </div>
                                      </div>
                                        <div class="row" style="margin-left:5px">
                                            <div class="form-group">
                                                <label class="label-input">¿Usted ha tenido cargo público?</label> <br />
                                                <input type="radio" name="rdbHasHadPublicPosition" value="1" v-model="hasHadPublicPosition" required style="margin-left:30px;"/> 
                                                <label class="label-input-radio">SI</label> 
                                                <input type="radio" name="rdbHasHadPublicPosition" value="0" v-model="hasHadPublicPosition" style="margin-left:20px"/> 
                                                <label class="label-input-radio">NO</label> <br>
                                            </div>
                                            <div v-show="hasHadPublicPosition == '1'">
                                                <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                    
                                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                                        <div class="tooltips">
                                                            <label class="label-input">¿Qué cargo público ha desempeñado?</label>
                                                            <input type="text" class="form-control inputs-form"
                                                                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" v-model="publicPosition">
                                                            <span id="tooltipstext" class="tooltipstext">Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col col-sm-6" style="padding:0px;">
                                                    <label class="label-input">Periodo de :</label>
                                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                        <input type="text" id="datepickerStartDatePublicPosition" class="form-control inputs-form" v-on:change="calculateNumberEntryTwoDates()"
                                                        minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="startDatePublicPosition" v-on:click="establisDateMaxToday()"
                                                        placeholder="Seleccionar fecha">
                                                    </div>
                                                </div>
                                                
                                                <div class="col col-sm-6" style="padding-left:0px;">
                                                    <label class="label-input">a:</label>
                                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                        <input type="text" id="datepickerEndDatePublicPosition" class="form-control inputs-form" v-on:change="calculateNumberEntryTwoDates()"
                                                        minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="endDatePublicPosition" v-on:click="establisDateMaxToday()"
                                                               placeholder="Seleccionar fecha">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="label-input">¿Algún familiar ha tenido cargo público?</label> <br />
                                                <input type="radio" name="rdbHasFamilyHadPublicPosition" value="1" v-model="hasFamilyHadPublicPosition" required style="margin-left:30px;"/>
                                                <label class="label-input-radio">SI</label> 
                                                <input type="radio" name="rdbHasFamilyHadPublicPosition" value="0" v-model="hasFamilyHadPublicPosition" style="margin-left:20px"/> 
                                                <label class="label-input-radio">NO</label>  <br>
                                            </div>
                                            <div v-show="hasFamilyHadPublicPosition == '1'">
                                                <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                    
                                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;margin-right:10px">
                                                        <div class="tooltips">
                                                            <label class="label-input">¿Qué cargo público ha desempeñado?</label>
                                                            <input type="text" class="form-control inputs-form"
                                                                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{4,50}" v-model="publicPositionFamily">
                                                            <span id="tooltipstext" class="tooltipstext">Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col col-sm-6" style="padding:0px;">
                                                    <label class="label-input">Periodo de :</label>
                                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                        <input type="text" id="datepickerStartDatePublicPositionFamily" class="form-control inputs-form" v-on:change="calculateNumberEntryTwoDates()"
                                                        minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="startDatePublicPositionFamily" v-on:click="establisDateMaxToday()"
                                                        placeholder="Seleccionar fecha">
                                                    </div>
                                                </div>
                                                
                                                <div class="col col-sm-6" style="padding-left:0px;">
                                                    <label class="label-input">a:</label>
                                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                                        <input type="text" id="datepickerEndDatePublicPositionFamily" class="form-control inputs-form" v-on:change="calculateNumberEntryTwoDates()"
                                                        minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="endDatePublicPositionFamily" v-on:click="establisDateMaxToday()"
                                                               placeholder="Seleccionar fecha">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div style="display:flex;justify-content:center">
                      <div>
                        <button class="next_button" type="submit" id="" >Siguiente</button>
                      </div>
                    </div>
                    <div style="display:flex;justify-content:center; margin-top:10px">
                      <div>  
                        <button class="back_button" @click="backReedirect" type="button" id="" >Atrás</button>
                      </div>
                    </div>
                    <br>
                </form>
            </div>
        </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>

<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { saveRequest,getAdmissionRequest } from "@/api/user";
import Vue from "vue";
import {  getTokenDecoden } from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      list_colonys: [],
      postalCode: "",
      numberExt: "",
      numberInt: "",
      cellphone: "",
      phoneExt: "",
      keyCt: "",
      colony: "",
      municipality: "",
      state: "",
      city: "",
      presupustalCode: "",
      street: "",
      admissionDate: "",
      hasHadPublicPosition: "",
      hasFamilyHadPublicPosition: "",
      publicPosition: "",
      startDatePublicPosition: "",
      endDatePublicPosition: "",
      publicPositionFamily: "",
      startDatePublicPositionFamily: "",
      endDatePublicPositionFamily: "",
      category: "",
      status: "",
      occupation: "",
      labour_old: 0,
      nameWork: "",
      member_id: "",
      maxDate: "",
      occupation_list: "",
      minYearDatePicker: "",
      acceptConditions: null,
      notice_of_privacy_type: "",
      isLoading: false,
      multiApi: process.env.VUE_APP_MULTIAPI,
      ccapi: process.env.VUE_APP_CCAPI,
      user_id:null,
      admission_request_id:null,
      admission_status:null,
    };
  },
  name: "Informacion-Laboral",
  components: {
    MenuLateral,
    Loading,
  },
  props: {
    msg: String,
  },
  async mounted() {
    //var input = document.getElementById('codigoPostal');
    //input.oninvalid = function(event) { event.target.setCustomValidity('El código postal sólo debe contener números'); }
  },
  async beforeMount() {
    await this.loadInfo();
    this.establisDateMaxToday();
    this.establishYearMinDatePicker();
    this.getCatalogOccupation();
    this.validateNumberPhone();

        var self = this;
        
        $(function () {
            $.datepicker.setDefaults($.datepicker.regional["es"]);
            $("#datepickerStartDatePublicPosition").datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: self.minYearDatePicker+":+nn",
                dateFormat: 'dd-mm-yy',
                maxDate: new Date(self.maxDate),
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
                dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
                onSelect:function(selectedDate, datePicker) {            
                    self.startDatePublicPosition = selectedDate;
                }
            });
        });

        $(function () {
            $.datepicker.setDefaults($.datepicker.regional["es"]);
            $("#datepickerEndDatePublicPosition").datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: self.minYearDatePicker+":+nn",
                dateFormat: 'dd-mm-yy',
                maxDate: new Date(self.maxDate),
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
                dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
                onSelect:function(selectedDate, datePicker) {            
                    self.endDatePublicPosition = selectedDate;
                }
            });
        });

        $(function () {
            $.datepicker.setDefaults($.datepicker.regional["es"]);
            $("#datepickerStartDatePublicPositionFamily").datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: self.minYearDatePicker+":+nn",
                dateFormat: 'dd-mm-yy',
                maxDate: new Date(self.maxDate),
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
                dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
                onSelect:function(selectedDate, datePicker) {            
                    self.startDatePublicPositionFamily = selectedDate;
                }
            });
        });

        $(function () {
            $.datepicker.setDefaults($.datepicker.regional["es"]);
            $("#datepickerEndDatePublicPositionFamily").datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: self.minYearDatePicker+":+nn",
                dateFormat: 'dd-mm-yy',
                maxDate: new Date(self.maxDate),
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
                dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
                onSelect:function(selectedDate, datePicker) {            
                    self.endDatePublicPositionFamily = selectedDate;
                }
            });
        });
    },
    watch : {
        hasHadPublicPosition () {
            if (this.hasHadPublicPosition == "0") {
                this.publicPosition =""
                this.startDatePublicPosition =""
                this.endDatePublicPosition =""
            }
        },
    },
  methods: {
    setAdmissionDatePicker() {
      if (this.status == "ACTIVO") {
        let self = this;
        $(function () {
            $.datepicker.setDefaults($.datepicker.regional["es"]);
            $("#datepickerAdmissionDate").datepicker({
                changeMonth: true,
                changeYear: true,
                yearRange: self.minYearDatePicker+":+nn",
                dateFormat: 'dd-mm-yy',
                maxDate: new Date(self.maxDate),
                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
                dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
                dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
                onSelect:function(selectedDate, datePicker) {            
                    self.admissionDate = selectedDate;
                }
            });
        }); 
      }
    },
    async validateAdmissionDate(){
      var auth = getTokenDecoden();
      var year = auth.obj.person.rfc.substring(4, 6)
      var month = auth.obj.person.rfc.substring(6, 8)
      var day = auth.obj.person.rfc.substring(8, 10)
      var ListAdmissionDate = this.admissionDate.split('-')

      var dateAdmission = new Date(ListAdmissionDate[1] + "-" + ListAdmissionDate[0] + "-" + ListAdmissionDate[2])
      var birtDay = new Date(year + "-" + month + "-" + day)
      birtDay.setFullYear(birtDay.getFullYear() + 18)

      var response = true;
      if(birtDay > dateAdmission){
        this.isLoading = false;
        await Swal.fire({
          title: "Aviso",
          text: "Fecha ingreso debe ser mayor a la mayoria de edad del socio",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        response = false;
      }
      return response;
    },
    async validateNumberPhone(){
      if (this.status == "ACTIVO" ) {
        var input = document.getElementById('cellphone');
        input.oninvalid = input.setCustomValidity('');
        if(input.value.length < 12){
          input.oninvalid = input.setCustomValidity('Debe ingresar los 10 dígitos del número');
        } 
      }
    },
    getCatalogOccupation(){
      var info_login = getTokenDecoden();
      var info_admission_request = JSON.parse(sessionStorage.getItem(info_login.id))
      
      var hash_occupations = {
          token_auth: info_login.token,
          client_type: info_admission_request.admission_request_params.admission_information.client_classification
        }
      this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_catalogs_occupation_by_client_type`, {
        params:hash_occupations
      },
      { 'Content-Type': 'application/json' })
      .then(response => {
        if(response.data.response.lista_ocupaciones.length == 0){
          alert("No se encontraron ocupaciones por el tipo de cliente, favor de contactar a sistemas.")
        }
        this.occupation_list = response.data.response.lista_ocupaciones
      }).catch(error => {
        alert("Ocurrio un error inesperado al consultar ocupaciones, favor de contactar a sistemas.")
      });
    },
    removeMask(phone) {
      if (phone.includes("(")) {
        phone = phone.replace("(", "");
      }
      if (phone.includes(")")) {
        phone = phone.replace(")", "");
      }
      if (phone.includes("-")) {
        phone = phone.replace("-", "");
      }
      if (phone.includes(" ")) {
        phone = phone.replace(" ", "");
      }
      return phone;
    },
    validateAlphanumericValue(value) {
      if (!/\D/.test(value.replace(/ /g, "").replace(".", ""))) {
        return true;
      }
      return false;
    },
    conditions(value) {
      this.acceptConditions = value;
    },
    validateCP() {
            if (this.postalCode != "") {
                if (this.postalCode != null && this.postalCode.length == 5) {
                    this.axios.post(this.multiApi + "/Api/Catalogo/Sepomex/obtener_colonias", {
                        contenido: {
                            codigo_postal : this.postalCode,
                            colonia : ""
                        },
                    },
                    {
                        auth: {
                            username: "multicr",
                            password: "*cabsa2018*",
                        },
                    },
                    { 'Content-Type': 'application/json' })
                    .then(response => {
                        if (response.data.estado.nombre == "") {
                            Swal.fire({
                                title: "Aviso",
                                text: "Introduzca un código postal valido",
                                icon: "warning",
                                confirmButtonColor: '#FEB72B',
                            });
                        }else{
                          this.list_colonys = response.data.colonias
                          this.municipality = response.data.municipio.nombre != null ? response.data.municipio.nombre : ""
                          this.state = response.data.estado.nombre != null ? response.data.estado.nombre : ""

                          if(response.data.ciudad.nombre != null){
                            this.city = response.data.ciudad.nombre
                          }
                          else{
                            this.city = "N/A"
                          }
                        }
                    }).catch(error => {
                        Swal.fire({
                            title: "Aviso",
                            text: "Introduzca un código postal valido",
                            icon: "warning",
                            confirmButtonColor: '#FEB72B',
                        });
                      this.list_colonys = []
                      this.municipality = ""
                      this.state = ""
                      this.city = ""
                    });
                }
            }
        },
        calculateNumberEntryTwoDates(){
            var dateToday = new Date()
            var dateAdminision = new Date(this.orderDateByYearMonthDay(this.admissionDate, '-'))

            var antiguedadd = dateToday.getFullYear() - dateAdminision.getFullYear()

            dateAdminision.setFullYear(dateAdminision.getFullYear() + antiguedadd)

            if(dateAdminision > dateToday){
                antiguedadd--
            }
            return antiguedadd
        },
        establisDateMaxToday(){
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth()+1; //January is 0!
            var yyyy = today.getFullYear();
            if(dd<10){
                dd='0'+dd
            } 
            if(mm<10){
                mm='0'+mm
            } 

            today = yyyy+'-'+mm+'-'+dd + 'T10:00:00.000';
            this.maxDate = today;
        },
        establishYearMinDatePicker() {
            var today = new Date();
            var yyyy = today.getFullYear()-100;

            this.minYearDatePicker = yyyy;
        },
        addUpperCase(value){
            if(value != ""){
                value = value.toString().toUpperCase();
            }
            return value
        },
        orderDateByDayMonthYear(date, typeSplit){
          if (date != "" && date != undefined) {
            var elemtsOfDate = date.split(typeSplit)
            var year = elemtsOfDate[0]
            var month = elemtsOfDate[1]
            var day = elemtsOfDate[2]
            return day+"-"+month+"-"+year 
          }
          
          return "";
        },
        orderDateByYearMonthDay(date, typeSplit){
            return moment(date.split(' ')[0].split(typeSplit).reverse().join("-")).format("YYYY-MM-DD");
        },
        formatDate(date) {
            var d = new Date(date)
            var month = '' + (d.getMonth() + 1)
            var day = '' + d.getDate()
            var year = d.getFullYear()

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [day, month, year].join('/');
        },
        validateEmptyDate(){
            let list_erros = [];
            if (this.admissionDate == "" || this.admissionDate == null) {
                list_erros.push("Fecha de ingreso");
            }

      if (this.hasHadPublicPosition == "1") {
        if (
          this.startDatePublicPosition == "" ||
          this.startDatePublicPosition == null ||
          this.endDatePublicPosition == "" ||
          this.endDatePublicPosition == null
        ) {
          list_erros.push("Rango de fechas del Cargo Público");
        }
      }

      return list_erros;
    },
    async loadInfo() {
      var auth = JSON.parse(window.sessionStorage.getItem("login"));
      this.user_id = auth["id"];

      await this.searchWorkInformation();
      //await this.getAdmissionRequest();
    },
    async searchWorkInformation() {
      var _json = JSON.parse(window.sessionStorage.getItem(this.user_id));
      console.log();
      if (
        _json != null &&
        _json.admission_request_params.admission_information != null &&
        _json.admission_request_params.admission_information.work_info_attributes.status != "" &&
        _json.admission_request_params.admission_information.workplace_attributes.status != ""
      ) {
        this.admission_request_id = _json.admission_request_id;
        this.postalCode = _json.admission_request_params.admission_information.workplace_attributes.postal_code;
        this.validateCP();
        //this.keyCt = _json.admission_request_params.admission_information.workplace_attributes.center_key;
        this.colony = _json.admission_request_params.admission_information.workplace_attributes.suburb;
        this.numberExt = _json.admission_request_params.admission_information.workplace_attributes.ext_number;
        this.municipality = _json.admission_request_params.admission_information.workplace_attributes.municipality;
        this.state = _json.admission_request_params.admission_information.workplace_attributes.state;
        this.city = _json.admission_request_params.admission_information.workplace_attributes.city;
        // this.presupustalCode = _json.admission_request_params.admission_information.work_info_attributes.employee_number[0];
        this.street = _json.admission_request_params.admission_information.workplace_attributes.street;
        this.admissionDate = this.orderDateByDayMonthYear(
          _json.admission_request_params.admission_information.work_info_attributes.active_since,
          "-"
        );
        this.category = _json.admission_request_params.admission_information.work_info_attributes.category;
        this.status = _json.admission_request_params.admission_information.work_info_attributes.laboral_status;
        this.numberExt = _json.admission_request_params.admission_information.workplace_attributes.ext_number;
        this.occupation = _json.admission_request_params.admission_information.work_info_attributes.occupation;
        this.labour_old = this.calculateNumberEntryTwoDates();

        let public_office_attributes_array = this.convertListToArray(_json.admission_request_params.admission_information.public_office_attributes);


        this.hasHadPublicPosition = public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions
          ? "1"
          : "0";

        this.publicPosition =
          public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name;

        this.startDatePublicPosition = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions == 1
            ? public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].start_date_public_positions : "",
                "-"
            );

        this.endDatePublicPosition = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions == 1
                ? public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].end_date_public_positions : "",
            "-"
        );

        this.hasFamilyHadPublicPosition = public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions
          ? "1"
          : "0";

        this.publicPositionFamily =
          public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name;

        this.startDatePublicPositionFamily = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions == 1
            ? public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].start_date_public_positions : "",
                "-"
            );

        this.endDatePublicPositionFamily = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions == 1
                ? public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].end_date_public_positions : "",
            "-"
        );

        this.nameWork = _json.admission_request_params.admission_information.workplace_attributes.name;
        let contact_info =
          _json.admission_request_params.admission_information.workplace_attributes.contact_info_attributes;
        for (const key in contact_info) {
          if (contact_info[key].contact_type == "telefono") {
            this.cellphone = contact_info[key].value;
            this.phoneExt = contact_info[key].extension;
            //this.mask ='### ###-####';
          }
        }
      } else {
        await this.getAdmissionRequest();
      }
    },
    async Next(e) {
      e.preventDefault();
      this.isLoading = true;

      if(!await this.validateAdmissionDate()){
        return;
      }

      
      if (!this.validateFields()) {
        this.isLoading = false;
        return;
      }

      this.labour_old = this.calculateNumberEntryTwoDates();
      let json = JSON.parse(sessionStorage.getItem(this.user_id));
      if(json == null){
        this.isLoading = false;
         Swal.fire({
            title: "Aviso",
            text:
              "Se presentó un error. No fue posible recuperar los datos",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
        // window.location.href = "/";
        this.$router.push({path:"/"});
      }
      json.token_auth=sessionStorage.getItem("login")
      json.admission_request_id = this.admission_request_id;
      json.change_for = "laboral_info";
      json.admission_request_params.admission_information.work_info_attributes.laboral_status = this.addUpperCase(this.status);
      json.admission_request_params.admission_information.work_info_attributes.active_since = this.status == "ACTIVO" ? this.orderDateByYearMonthDay(this.admissionDate,"-") : this.orderDateByYearMonthDay(this.getCurrentDate(), '-');
      json.admission_request_params.admission_information.work_info_attributes.category = this.status == "ACTIVO" ? this.addUpperCase(this.category) : "-----";
      json.admission_request_params.admission_information.work_info_attributes.occupation = this.status == "ACTIVO" ? this.occupation : "-----";
      json.admission_request_params.admission_information.work_info_attributes.status = "activo";
      // json.admission_request_params.admission_information.work_info_attributes.employee_number = [this.addUpperCase(this.presupustalCode)];
      json.admission_request_params.admission_information.work_info_attributes.principal_occupation = "";
      json.admission_request_params.admission_information.work_info_attributes.business_name = "";
      json.admission_request_params.admission_information.work_info_attributes.supervisor = "";

      json.admission_request_params.admission_information.workplace_attributes.name = this.status == "ACTIVO" ? this.addUpperCase(this.nameWork).trim() : "-----";
      // json.admission_request_params.admission_information.workplace_attributes.center_key = this.addUpperCase(this.keyCt);
      json.admission_request_params.admission_information.workplace_attributes.status = "activo";
      json.admission_request_params.admission_information.workplace_attributes.street = this.status == "ACTIVO" ? this.addUpperCase(this.street).trim() : "-----";
      json.admission_request_params.admission_information.workplace_attributes.between_street_one = "";
      json.admission_request_params.admission_information.workplace_attributes.between_street_two = "";
      json.admission_request_params.admission_information.workplace_attributes.ext_number = this.status == "ACTIVO" ? this.numberExt : "-----";
      json.admission_request_params.admission_information.workplace_attributes.int_number = "";
      json.admission_request_params.admission_information.workplace_attributes.suburb = this.status == "ACTIVO" ? this.addUpperCase(this.colony) : "-----";
      json.admission_request_params.admission_information.workplace_attributes.postal_code = this.status == "ACTIVO" ? this.postalCode : "85000";
      json.admission_request_params.admission_information.workplace_attributes.city = this.status == "ACTIVO" ? this.addUpperCase(this.city) : "-----";
      json.admission_request_params.admission_information.workplace_attributes.state = this.status == "ACTIVO" ? this.addUpperCase(this.state) : "-----";
      json.admission_request_params.admission_information.workplace_attributes.country = "MÉXICO";
      json.admission_request_params.admission_information.workplace_attributes.municipality = this.status == "ACTIVO" ? this.addUpperCase(this.municipality) : "-----";

      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.street = this.status == "ACTIVO" ? this.addUpperCase(this.street).trim() : "-----";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.between_street_one = "";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.between_street_two = "";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.ext_number = this.status == "ACTIVO" ? this.numberExt : "-----";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.int_number = "";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.suburb = this.status == "ACTIVO" ? this.addUpperCase(this.colony) : "-----";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.postal_code = this.status == "ACTIVO" ? this.postalCode : "85000";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.city = this.status == "ACTIVO" ? this.addUpperCase(this.city) : "-----";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.state = this.status == "ACTIVO" ? this.addUpperCase(this.state) : "-----";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.country = "MÉXICO";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.status = "activo";
      json.admission_request_params.admission_information.workplace_attributes.adress_attributes.municipality = this.status == "ACTIVO" ? this.addUpperCase(this.municipality) : "-----";

      json.admission_request_params.admission_information.workplace_attributes.contact_info_attributes = this.insertContactInfo();
      moment.locale("es");

      let titular = {
        has_had_public_positions: this.hasHadPublicPosition == 1 ? true : false,
        positions_name: this.addUpperCase(this.publicPosition).trim(),
        start_date_public_positions: this.hasHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.startDatePublicPosition, "-") : "",
        end_date_public_positions: this.hasHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.endDatePublicPosition, "-"): "",
        relationship: "titular",
      }

      let familiar = {
        has_had_public_positions: this.hasFamilyHadPublicPosition == 1 ? true : false,
        positions_name: this.addUpperCase(this.publicPositionFamily).trim(),
        start_date_public_positions: this.hasFamilyHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.startDatePublicPositionFamily, "-") : "",
        end_date_public_positions: this.hasFamilyHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.endDatePublicPositionFamily, "-") : "",
        relationship: "familiar",
      }

      let public_office_attributes_array = [];
      public_office_attributes_array.push(titular);
      public_office_attributes_array.push(familiar);

      json.admission_request_params.admission_information.public_office_attributes = Object.assign({}, public_office_attributes_array);

      sessionStorage.setItem(this.user_id, JSON.stringify(json));
      if( this.admission_status == "info_economica_completa" || this.admission_status == "info_laboral_incompleta")
      {
        await this.saveRequest(); 
      } 
      },
     async getAdmissionRequest(){
          let _json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
          if( _json != null)
          {
            if(_json.data.response.admission_request != null)
            {
              this.admission_request_id = _json.data.response.admission_request.id;
              this.admission_status = _json.data.response.admission_request.status;  //se obtiene el estatus de la solicitud
              if(_json.data.response.admission_request.admission_information.workplace_attributes.status != "")
              {
                console.log("Se obtuvieron los datos desde el local Storage");
                this.postalCode = _json.data.response.admission_request.admission_information.workplace_attributes.postal_code;
                // this.keyCt =
                //   _json.data.response.admission_request.admission_information.workplace_attributes.center_key;

                this.validateCP();
                this.colony =
                  _json.data.response.admission_request.admission_information.workplace_attributes.suburb;
                this.numberExt =
                  _json.data.response.admission_request.admission_information.workplace_attributes.ext_number;
                this.municipality =
                  _json.data.response.admission_request.admission_information.workplace_attributes.municipality;
                this.state = _json.data.response.admission_request.admission_information.workplace_attributes.state;
                this.city = _json.data.response.admission_request.admission_information.workplace_attributes.city;
                // this.presupustalCode =
                //   _json.data.response.admission_request.admission_information.work_info_attributes.employee_number;
                this.street =
                  _json.data.response.admission_request.admission_information.workplace_attributes.street;
                this.admissionDate = this.orderDateByDayMonthYear(
                  _json.data.response.admission_request.admission_information.work_info_attributes.active_since,
                  "-"
                );
                this.category =
                  _json.data.response.admission_request.admission_information.work_info_attributes.category;
                this.status =
                  _json.data.response.admission_request.admission_information.work_info_attributes.laboral_status;
                this.numberExt =
                  _json.data.response.admission_request.admission_information.workplace_attributes.ext_number;
                this.occupation =
                  _json.data.response.admission_request.admission_information.work_info_attributes.occupation;
                this.labour_old = this.calculateNumberEntryTwoDates();

                if(_json.data.response.admission_request.admission_information.public_office_attributes["0"].relationship != "")
                {
                  let public_office_attributes_array = this.convertListToArray(_json.data.response.admission_request.admission_information.public_office_attributes);

                  this.hasHadPublicPosition =
                  public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions == true ? 1 : 0;

                  this.publicPosition =
                  public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name;

                  this.startDatePublicPosition = this.orderDateByDayMonthYear(
                  public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].start_date_public_positions,
                  "-");

                  this.endDatePublicPosition = this.orderDateByDayMonthYear(
                  public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].end_date_public_positions,
                  "-");

                  /*Familiar*/

                  this.hasFamilyHadPublicPosition =
                  public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions == true ? 1 : 0;

                  this.publicPositionFamily =
                  public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name;

                  this.startDatePublicPositionFamily = this.orderDateByDayMonthYear(
                  public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].start_date_public_positions,
                  "-");
                  
                  this.endDatePublicPositionFamily = this.orderDateByDayMonthYear(
                  public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].end_date_public_positions,
                  "-");
                }
                this.nameWork =
                  _json.data.response.admission_request.admission_information.workplace_attributes.name;
                let contact_info =  _json.data.response.admission_request.admission_information.workplace_attributes.contact_info_attributes;
                for (let contact in contact_info) {
                  if(contact_info[contact].contact_type === "telefono") {
                    this.cellphone = contact_info[contact].value
                    this.phoneExt = contact_info[contact].extension;
                  }

                  //this.mask = '### ###-####';
                }
              }
            }
          }else{
          Swal.fire({
            title: "Aviso",
            text:
              "Se presentó un error. No fue posible obtener los datos de la solicitud.",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
          // window.location.href = "/cuenta/registro-datos-personales";
          this.$router.push({path:"/cuenta/registro-datos-personales"});  
        }
    },
    async saveRequest(){
      this.isLoading=true;
      var json = this.getJsonFromLocalStorageBy(this.user_id);
      await saveRequest(
        json
      )
        .then((response ) => {
          this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
          this.$router.push({path:"/cuenta/ahorro"}); 
        })
        .catch((error) => {
          let messages="";
          error.data.errors.forEach(element => {
            messages+="<strong>■ "+element+".</strong><br>"
          });
          Swal.fire({
            title: "Aviso",
            html:error.data.response.message+"<br>"+messages,
            icon: "info",
            confirmButtonText: "OK",
            confirmButtonColor: '#FEB72B'
          });
        }).finally( () => {this.isLoading=false})
    },
    insertContactInfo() {
      let contact_array = [];
      let contact_obj = {};

      let telefono = {
        value: this.status == "ACTIVO" ? this.removeMask(this.cellphone) : "0000000000",
        status:"activo",
        contact_type:"telefono",
        extension: this.status == "ACTIVO" ? this.phoneExt : "000"
      }

      contact_array.push(telefono);
      contact_obj = Object.assign({}, contact_array)

      return contact_obj;
    },
    validateFields() {
      if (this.status == "ACTIVO") {
        if (this.admissionDate == "") {
          Swal.fire({
            title: "Aviso",
            text:
              "Favor de capturar la Fecha de Ingreso",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
        if (this.validateAlphanumericValue(this.nameWork)) {
          Swal.fire({
            title: "Aviso",
            text: "La escuela no puede contener sólo numeros",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }  
      }
      
      if (this.hasHadPublicPosition == "1") {
        if (this.publicPosition != "") {
          if (this.validateAlphanumericValue(this.publicPosition)) {
            Swal.fire({
              title: "Aviso",
              text: "El cargo público no puede contener sólo numeros.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            return false;
          }
        } else {
          Swal.fire({
            title: "Aviso",
            text: "Ingrese el cargo público que ha desempeñado el titular.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }

      if (this.hasFamilyHadPublicPosition == "1") {
        if (this.publicPositionFamily != "") {
          if (this.validateAlphanumericValue(this.publicPositionFamily)) {
            Swal.fire({
              title: "Aviso",
              text: "El cargo público del familiar no puede contener sólo numeros.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            return false;
          }
        } else {
          Swal.fire({
            title: "Aviso",
            text: "Ingrese el cargo público que ha desempeñado el familiar.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }

      /* if (this.status == "ACTIVO") {
        if (this.cellphone.length < 12) {
          Swal.fire({
            title: "Aviso",
            text: "El Teléfono debe contener 10 dígitos",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      } */
      
      if (this.hasHadPublicPosition == "1") {
        moment.locale("es");
        if (this.startDatePublicPosition == "" || this.endDatePublicPosition == "") {
          Swal.fire({
            title: "Aviso",
            text:
              "Favor de capturar las fechas del cargo público del titular",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
        let dateInitial = moment(this.startDatePublicPosition,["DD-MM-YYYY", "YYYY-MM-DD"]);
        let dateFinal = moment(this.endDatePublicPosition, ["DD-MM-YYYY", "YYYY-MM-DD"]);
        if (dateInitial >= dateFinal) {
          Swal.fire({
            title: "Aviso",
            text:
              "La fecha final del cargo público no puede ser menor a la fecha inicial. Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }

      if (this.hasFamilyHadPublicPosition == "1") {
        moment.locale("es");
        if (this.startDatePublicPositionFamily == "" || this.endDatePublicPositionFamily == "") {
          Swal.fire({
            title: "Aviso",
            text:
              "Favor de capturar las fechas del cargo público del familiar",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
        let dateInitialFamiliar = moment(this.startDatePublicPositionFamily,["DD-MM-YYYY", "YYYY-MM-DD"]);
        let dateFinalFamiliar = moment(this.endDatePublicPositionFamily, ["DD-MM-YYYY", "YYYY-MM-DD"]);
        if (dateInitialFamiliar >= dateFinalFamiliar) {
          Swal.fire({
            title: "Aviso",
            text:
              "La fecha final del cargo público familiar no puede ser menor a la fecha inicial. Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }
      return true;
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    convertListToArray(list) {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    },
    getCurrentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
 
      today = dd + '-' + mm + '-' + yyyy;
      return today;
    },
    backReedirect() {
       this.$router.push({path:"/cuenta/informacion-economica"});
    },
  },
};
</script>

<style>
.activeClass {
  @apply bg-gray-600;
}

.public-charge {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
}

.public-charge li {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}

.public-charge label,
.public-charge input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.public-charge input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.public-charge input[type="radio"]:checked+label,
.Checked+label {
  background: yellow;
}

.public-charge label {
  padding: 5px;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 90;
}

.public-charge label:hover {
  background: #DDD;
}

.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }
    .border{
        border-right: 1px dashed #333;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}

@media (max-width: 766px) {
    .border{
        margin-left: 9px;
        margin-right: -15px;
        border-right: 1px dashed #ffffff;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}


#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}

/*Tooltip*/

.tooltips{
  position: relative;
  /* display: inline-block; */
  opacity: 1;
}

.tooltips .tooltipstext {
  visibility: hidden;
  width: 140px;
  background-color: #82385b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -18px;
  left: 105%;
}

.tooltips .tooltipstext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #82385b transparent transparent;
}

.tooltips input:focus + .tooltipstext{
    visibility: visible;
}

@media only screen and (max-width: 1406px) {
  .tooltips .tooltipstext {
    visibility: hidden;
    width: 210px;
    height: 100px;
    background-color: #82385b;
    font-size: 15px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    z-index: 1;
    top: -100px;
    bottom: 80%;
    left: 85%;
    margin-left: -60px;
  }
  .tooltips .tooltipstext::after {
    content: "";
    position: absolute;
    top:105%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #82385b transparent transparent transparent;
  }
}
@media only screen and (max-width: 1281px) {
  .tooltips .tooltipstext {
    left: 80%;
  }
}

@media only screen and (max-width: 1279px) {
  .tooltips .tooltipstext {
    left: 100%;
  }
}

@media only screen and (max-width: 1234px) {
  .tooltips .tooltipstext {
    left: 97%;
  }
}

@media only screen and (max-width: 1190px) {
  .tooltips .tooltipstext {
    left: 30%;
  }
}
</style>

